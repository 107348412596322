import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Moment from 'moment';

import BackgroundImage from '../../components/BackgroundImage';
import PopupMessage from '../../components/PopupMessage';
import SyncProcess from '../../components/SyncProcess';
import SyncEnterCode from '../../components/SyncEnterCode';
import SyncDeviceList from '../../components/syncDeviceList';

import { getSyncCode, CLOCK_FORMAT } from '../../util/Helper';

const originalSetItem = localStorage.setItem;
localStorage.setItem = function () {
    const event = new Event('themeChanged');
    document.dispatchEvent(event);
    originalSetItem.apply(this, arguments);
}

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
            backToHome: false,
            colorScheme: localStorage.getItem("winterly_color_scheme") || "winter",
            showNotification: false,
            winterlyName: "",
            isSynced: false,
            createGroupSync: false,
            enterCodeSync: false,
            syncCode: "",
            syncID: localStorage.getItem("winterly-id") || "",
            clockType: localStorage.getItem("winterly-clock-format") || CLOCK_FORMAT[2]
        }
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleBackButton = this.handleBackButton.bind(this);
        this.handleThemeChange = this.handleThemeChange.bind(this);
        this.setNotificationToFalse = this.setNotificationToFalse.bind(this);
        this.saveSettings = this.saveSettings.bind(this);
        this.handleCreateGroupSync = this.handleCreateGroupSync.bind(this);
        this.handleEnterCodeSync = this.handleEnterCodeSync.bind(this);
        this.handleUnsyncButton = this.handleUnsyncButton.bind(this);
        this.syncCodeChanged = this.syncCodeChanged.bind(this);
        this.handleClockChange = this.handleClockChange.bind(this);
        this.updateClock = this.updateClock.bind(this);
    }

    updateClock() {
        setInterval(() => {
            // const now = new Date();
            // const isBlink = ((now.getSeconds() % 2) === 0) ? true : false;
            let clockFormat = {};
            for (let i = 0; i < CLOCK_FORMAT.length; i++) {
                let strFormat = CLOCK_FORMAT[i];
                // if (isBlink) strFormat = strFormat.replace(":", " ")
                clockFormat["clockFormat_" + i] = Moment().format(strFormat);
            }
            this.setState(clockFormat)
        }, 100);
    }

    syncCodeChanged() {
        const check = setInterval(() => {
            const isSynced = ((localStorage.getItem("winterly-id") != null) && (localStorage.getItem("winterly-seed") != null))
            const syncCode = getSyncCode();
            this.setState({
                isSynced: isSynced,
                syncCode: syncCode,
                syncID: localStorage.getItem("winterly-id"),
                createGroupSync: false,
                enterCodeSync: false,
            })
            clearInterval(check);
        }, 0);
    }

    handleUnsyncButton() {
        this.setState({
            createGroupSync: false,
            enterCodeSync: false,
            isSynced: false
        }, () => {
            localStorage.removeItem("winterly-seed");
            localStorage.removeItem("winterly-id");
        })
    }
    handleCreateGroupSync() {
        this.setState({
            createGroupSync: true,
            enterCodeSync: false
        })
    }

    handleEnterCodeSync() {
        this.setState({
            createGroupSync: false,
            enterCodeSync: true
        })
    }

    saveSettings() {
        const winterlyTheme = this.state.colorScheme;
        const winterlyName = this.state.winterlyName;
        const winterlyClockFormat = this.state.clockType;

        localStorage.setItem("winterly_color_scheme", winterlyTheme);
        localStorage.setItem("winterly-name", winterlyName);
        localStorage.setItem("winterly-clock-format", winterlyClockFormat);
        this.setState({
            showNotification: true
        }, () => {
            this.setNotificationToFalse();
        })
    }

    handleBackButton() {
        this.setState({
            backToHome: true
        })
    }


    handleTabChange(e, newVal) {
        this.setState({
            enterCodeSync: false,
            tabValue: newVal
        })
    }

    handleThemeChange(e) {
        this.setState({
            colorScheme: e.target.value,
        })
    }

    handleClockChange(e) {
        if (e.target.value !== undefined)
            this.setState({
                clockType: e.target.value,
            })
    }

    setNotificationToFalse() {
        setInterval(() => {
            this.setState({
                showNotification: false
            })
        }, 5000);
    }

    componentDidMount() {
        document.addEventListener("keydown", (e) => {
            if ((window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) && e.keyCode === 83) {
                e.preventDefault();
                this.saveSettings();
            }
        });

        if (typeof document !== 'undefined') {
            document.addEventListener('syncCode', this.syncCodeChanged)
        }

        const isSynced = ((localStorage.getItem("winterly-id") != null) && (localStorage.getItem("winterly-seed") != null))
        const syncCode = getSyncCode();
        this.setState({
            winterlyName: localStorage.getItem("winterly-name") || "",
            isSynced: isSynced,
            syncCode: syncCode
        })

        this.updateClock();
    }

    render() {

        // Render clock
        const clock = CLOCK_FORMAT.map((val, i) => {
            if (("clockFormat_" + i) in this.state) {
                let time = (this.state)["clockFormat_" + i];
                if (time.includes(":")) time = time.replace(":", "<span class='clockBlinker'>:</span>")
                if (time.includes("\r\n")) time = time.replace("\r\n", " ");
                return (
                    <div key={val}>
                        <input type="radio" id={val} name="clockType" value={val} defaultChecked={val === localStorage.getItem("winterly-clock-format")} />
                        <label htmlFor={val} className="clockSettingValue" dangerouslySetInnerHTML={{ __html: time }} /><br />
                    </div>
                )
            } else return null;
        })

        if (this.state.backToHome) {
            return (
                <Redirect to="/" />
            )
        }
        return (
            <div className="settingsPageContainer" >
                <button className="backHomeButton" onClick={this.handleBackButton}>← Back</button>
                <BackgroundImage />
                <PopupMessage
                    showNotification={this.state.showNotification}
                    message="Your settings are saved"
                />
                <div className="settingsTabWrapper">
                    <button className="saveStoryButton" onClick={this.saveSettings}>Save</button>
                    <div className="settingsTab">
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={this.state.tabValue}
                            onChange={this.handleTabChange}
                        >
                            <Tab label="General" {...a11yProps(0)} />
                            <Tab label="Color" {...a11yProps(1)} />
                            {/* <Tab label="Sync" {...a11yProps(2)} /> */}
                        </Tabs>

                        <TabPanel
                            value={this.state.tabValue}
                            index={0}
                        >
                            <form>
                                <label htmlFor="winter-name">Your name:</label><br />
                                <input className="settingsInputField" id="winter-name" value={this.state.winterlyName} onChange={(e) => { this.setState({ winterlyName: e.target.value }) }} autoComplete="off" spellCheck={false}></input>
                            </form>
                            <br />
                            <br />
                            <label>Please select your preferred date/clock format:</label>
                            <form onChange={this.handleClockChange}>
                                {clock}
                            </form>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                        </TabPanel>

                        {/* Color Scheme */}
                        <TabPanel
                            value={this.state.tabValue}
                            index={1}
                        >
                            <label>Please select your preferred color scheme:</label>
                            <form onChange={this.handleThemeChange}>
                                <input type="radio" id="winter" name="colorScheme" value="winter" defaultChecked={"winter" === localStorage.getItem("winterly_color_scheme")} />
                                <label htmlFor="winter">Light</label><br />

                                <input type="radio" id="winter-night" name="colorScheme" value="winter-night" defaultChecked={"winter-night" === localStorage.getItem("winterly_color_scheme")} />
                                <label htmlFor="winter-night">Dark</label><br />

                                <input type="radio" id="pure-dark" name="colorScheme" value="pure-dark" defaultChecked={"pure-dark" === localStorage.getItem("winterly_color_scheme")} />
                                <label htmlFor="pure-dark">True Dark</label><br />
                            </form>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                        </TabPanel>

                        {/* Synchronization */}
                        <TabPanel
                            value={this.state.tabValue}
                            index={2}
                        >
                            <label>Synchronize your stories accross devices & browsers</label><br />
                            {
                                (this.state.isSynced) ?
                                    <div>
                                        <p>Enter this code to another devices or browsers you want to sync:</p>
                                        <p className="syncCodeLabel">{this.state.syncCode}</p>
                                        {/* <button className="deleteStoryButton settings" style={{ float: "left", marginTop: "0px" }} onClick={this.handleUnsyncButton}>Un-sync This Browser</button> */}
                                        <SyncDeviceList
                                            syncCode={this.state.syncCode}
                                        />
                                    </div>
                                    :
                                    (!this.state.createGroupSync && !this.state.enterCodeSync) ?
                                        <div>
                                            <button className="saveStoryButton settings" onClick={this.handleEnterCodeSync}>Enter a code</button>
                                            <button className="saveStoryButton settings" onClick={this.handleCreateGroupSync}>Sync stories</button>
                                        </div>
                                        :
                                        ""

                            }

                            {
                                (this.state.createGroupSync) ?
                                    <div>
                                        <SyncProcess />
                                    </div>
                                    :
                                    <div></div>
                            }

                            {
                                (this.state.enterCodeSync) ?
                                    <SyncEnterCode />
                                    :
                                    <div></div>
                            }
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                        </TabPanel>
                    </div>
                </div>
            </div>
        );
    }
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box className="boxTabPanel" component="div">
                    {children}

                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default Settings;