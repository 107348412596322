import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { DraftailEditor } from "draftail";
import moment from 'moment';

import {getAllBlog} from '../../util/Helper';
import BackgroundImage from '../../components/BackgroundImage';

import "draft-js/dist/Draft.css";
import "draftail/dist/draftail.css";
import 'draft-js-inline-toolbar-plugin/lib/plugin.css';
import 'draft-js-hashtag-plugin/lib/plugin.css';
import 'draft-js-linkify-plugin/lib/plugin.css';
import 'draft-js-side-toolbar-plugin/lib/plugin.css';
import '@tunoltd/draft-js-emoji-plugin/lib/plugin.css';

class ViewStory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editableTxt: true,
            backToStory: false,
            showNotification: false,
            tempData: {}, // for content
            tempTitle: "", //for title
            doRender: false,
            isNewStory: true,
            storyID: 0,
            notifClass: "",
            notifText: "",
            savedStory: {}
        }
        this.handleBackButton = this.handleBackButton.bind(this);
    }

    handleBackButton() {
        this.setState({
            backToStory: true
        })
    }

    async componentDidMount() {

        const storyID = ((window.location.pathname).split('/')[(window.location.pathname).split('/').length - 1]);
        if (storyID !== "") {
            let story = await getAllBlog(storyID);
            story = (story.length > 0) ? story[0] : story;
            console.log(story);
            if ((story !== undefined) && (story !== "") && (story != null)) {
                this.setState({
                    savedStory: story.body,
                    initialTxt: story.body,
                    tempTitle: story.title,
                    doRender: true,
                    isNewStory: false,
                    storyID: story.id,
                    timestamp: story.lastUpdate
                })
            }
            else {
                this.setState({
                    redirectNewStory: true
                })
            }
        } else {
            this.setState({
                doRender: true,
                isNewStory: true
            })
        }
    }


    render() {
        if (this.state.backToStory) {
            return (
                <Redirect to="/@Winterly" />
            )
        }

        if (this.state.doRender) {
            return (
                <>
                    <button className="backHomeButton" onClick={this.handleBackButton}>← Back</button>
                    <BackgroundImage />
                    <div className="viewStoryContainer">
                        <p className="newStoryTitleField" >{this.state.tempTitle}</p>
                        <p className="viewStoryMeta">Story by Winterly · Published at {moment(this.state.lastUpdate).format("MMM DD, YYYY")}</p>
                        <DraftailEditor
                            rawContentState={this.state.initialTxt}
                            readOnly={this.state.editableTxt}
                        />
                    </div >
                </>
            );
        }
        else {
            return (
                <></>
            )
        }
    }
}

export default ViewStory;
