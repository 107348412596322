import React from 'react';
import Moment from 'moment';

import { CLOCK_FORMAT } from '../../util/Helper';

const DEFAULT_CLOCK_FORMAT = 2;

class Clock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      delay: 0
    }
    this.formatTimeHTML = this.formatTimeHTML.bind(this);
  }
  componentDidMount() {
    if (localStorage.getItem("winterly-clock-format") == null) localStorage.setItem("winterly-clock-format", CLOCK_FORMAT[DEFAULT_CLOCK_FORMAT]);
    setInterval(() => {
      const strFormat = ((localStorage.getItem("winterly-clock-format") != null) ? localStorage.getItem("winterly-clock-format") : CLOCK_FORMAT[DEFAULT_CLOCK_FORMAT]);
      this.setState({
        format: strFormat,
      }, () => {
        this.setState({
          time: this.formatTimeHTML(),
          delay: 100
        })
      })
    }, this.state.delay)
  }

  formatTimeHTML() {
    const format = this.state.format;
    let time = Moment().format(format);
    if (time.includes(":")) time = time.replace(":", "<span class='clockBlinker'>:</span>")
    if (time.includes("\r\n")) time = time.replace("\r\n", "<br />");
    return time;
  }

  render() {
    return (
      <div className="clock">
        <div className="clock time" dangerouslySetInnerHTML={{ __html: this.state.time }} />
      </div>
    )
  }
}

export default Clock;