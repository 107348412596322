import React, { Component } from 'react';
import ReactTypingEffect from 'react-typing-effect';

import { isJsonString, isJsonEmpty, URL_QUOTE } from '../../util/Helper';

const initialQuote = localStorage.getItem("winterly-quote") || "";
const initialEraseDelay = (initialQuote !== "") ? 60000 : 0;

class Quote extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bgImage: "",
            quoteText: initialQuote,
            eraseDelay: initialEraseDelay,
        };
        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        if (this.state.quoteText === "") this.loadData();
        setInterval(this.loadData,
            // 300000
            60000
        );
    }

    async loadData() {
        try {
            // Fetch quotes
            fetch(URL_QUOTE)
                .then(response => response.json())
                .then(data => {
                    if (isJsonString(JSON.stringify(data))) {
                        if (!isJsonEmpty(data)) {
                            const quoteText = data.text + "\n\n— " + data.author;
                            localStorage.setItem("winterly-quote", quoteText);
                            this.setState({
                                quoteText: quoteText,
                                quoteAuthor: "— " + data.author,
                                eraseDelay: 60000
                            });
                        } else {
                            const quoteText = "Sometimes our fate resembles a fruit tree in winter. Who would think that those branches would turn green again and blossom, but we hope it, we know it.\n\n— Johann Wolfgang von Goethe";
                            localStorage.setItem("winterly-quote", quoteText);
                            this.setState({
                                quoteText: quoteText,
                                eraseDelay: 315360000000000
                            });
                        }
                    } else {
                        const quoteText = "Sometimes our fate resembles a fruit tree in winter. Who would think that those branches would turn green again and blossom, but we hope it, we know it.\n\n— Johann Wolfgang von Goethe";
                        localStorage.setItem("winterly-quote", quoteText);
                        this.setState({
                            quoteText: quoteText,
                            eraseDelay: 315360000000000
                        });
                    }
                }).catch(err => { });
        } catch (e) {
        }
    }

    render() {
        return (
            <div className="quote">
                <ReactTypingEffect
                    className="quoteText"
                    text={this.state.quoteText}
                    typingDelay="0"
                    speed="50"
                    eraseDelay={this.state.eraseDelay}
                />
            </div>
        );
    }
}

export default Quote;