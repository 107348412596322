import React, { Component } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

class Download extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingAnimation: true,
            fileName: "photo_{{id}}_from_unsplash_(downloaded_via_winterly).jpg",
            downloadUrl: "",
            imageData: "",
            loadingValue: 0,
            preMessage: "Please wait while Winterly is preparing your image download 🚀<br /><br />It may takes a few minutes depends on your internet speed."
        };
        this.downloadImage = this.downloadImage.bind(this);
        this.getBase64Image = this.getBase64Image.bind(this);
        this.updateProgress = this.updateProgress.bind(this);
    }

    componentDidMount() {
        const photoId = this.props.match.params.photoid;
        const apiUrl = "https://api.unsplash.com/photos/" + photoId + "/download?client_id=UeycxVr1eFWsXEAk3szmgaxHjiRFU8I-AF0H59tm0is";
        this.setState({
            fileName: (this.state.fileName).replace("{{id}}", photoId),
            downloadUrl: apiUrl
        }, () => {
            fetch(apiUrl)
                .then(data => {
                    return data.json()
                })
                .then(data => {
                    if ("url" in data) {
                        this.getBase64Image(data.url, (value) => {
                            this.setState({
                                imageData: value,
                                preMessage: "Your image is ready 📸<br /><br /><a href='" + data.url + "' target='_blank'>Click here</a> if your browser does not automatically download the image.",
                                downloadUrl: data.url,
                            })
                            this.downloadImage();
                        });
                    } else {
                    }
                })
                .catch(err => {
                })
        });
    }

    downloadImage() {
        let a = document.createElement('a');
        a.href = this.state.imageData;
        a.download = this.state.fileName;
        a.click();
        // window.close();
    }

    getBase64Image(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.addEventListener("progress", this.updateProgress);
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    updateProgress(oEvent) {
        if (oEvent.lengthComputable) {
            var percentComplete = oEvent.loaded / oEvent.total * 100;
            this.setState({
                loadingValue: percentComplete
            })
        } else {
            this.setState({
                loadingValue: 100
            })
            // Unable to compute progress information since the total size is unknown
        }
    }

    render() {

        if (this.state.loadingAnimation) {
            return (
                <div className="downloadPageContainer">
                    {/* 4499c7 */}
                    <img className="imageLogoDownload" src={process.env.PUBLIC_URL + '/snowflake.png'} alt=""/>
                    <div className="loadingSvgContainer">
                        {/* <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                            <rect x="20" y="50" width="4" height="10" fill="#4499c7">
                                <animateTransform attributeType="xml"
                                    attributeName="transform" type="translate"
                                    values="0 0; 0 20; 0 0"
                                    begin="0" dur="0.6s" repeatCount="indefinite" />
                            </rect>
                            <rect x="30" y="50" width="4" height="10" fill="#4499c7">
                                <animateTransform attributeType="xml"
                                    attributeName="transform" type="translate"
                                    values="0 0; 0 20; 0 0"
                                    begin="0.2s" dur="0.6s" repeatCount="indefinite" />
                            </rect>
                            <rect x="40" y="50" width="4" height="10" fill="#4499c7">
                                <animateTransform attributeType="xml"
                                    attributeName="transform" type="translate"
                                    values="0 0; 0 20; 0 0"
                                    begin="0.4s" dur="0.6s" repeatCount="indefinite" />
                            </rect>
                        </svg> */}
                    </div>
                    <LinearProgress className="downloadProgressBar" variant="determinate" value={this.state.loadingValue} />
                    <p dangerouslySetInnerHTML={{ __html: this.state.preMessage }} />
                </div >
            );
        }
    }
}

export default Download;