import React, { Component } from 'react';

import { URL_BACKGROUND, isJsonString, isJsonEmpty, isValidURL } from '../../util/Helper';
import Footer from '../../components/Footer';

const initialBackground = localStorage.getItem("winterly-background");
const initialFooterTxt = localStorage.getItem("winterly-footerTxt");

class BackgroundImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bgImage: "",
            footerTxt: "",
            backToHome: false,
            unsplashData: {},
            darkenBackground: (localStorage.getItem("winterly_color_scheme") != null) ? ((localStorage.getItem("winterly_color_scheme").includes("night")) ? 0.7 : (localStorage.getItem("winterly_color_scheme").includes("dark")) ? 0.8 : 0) : 0,
            initialBackground: initialBackground || ""
        };
        this.loadData = this.loadData.bind(this);
        this.themeChanged = this.themeChanged.bind(this);
    }

    themeChanged() {
        setTimeout(() => {
            const scheme = localStorage.getItem("winterly_color_scheme");
            let darkenBackground = 0;

            if (scheme.includes("night")) darkenBackground = 0.7;
            else if (scheme.includes("dark")) darkenBackground = 0.8;

            this.setState({
                darkenBackground: darkenBackground
            })
        }, 0);
    }


    async loadData() {
        try {
            let orientation = "";
            if (window.innerWidth > window.innerHeight) orientation = "landscape";
            if (window.innerWidth < window.innerHeight) orientation = "portrait";
            if (window.innerWidth === window.innerHeight) orientation = "squarish";

            // Fetch images
            fetch(URL_BACKGROUND + orientation)
                .then(response => response.json())
                .then(data => {
                    if (isJsonString(JSON.stringify(data))) {
                        if (!isJsonEmpty(data)) {
                            this.setState({ unsplashData: data }, () => {
                                const downloadUrl = (data.data.links.download_location).split("/")[4];
                                const bgImage = data.data.urls.full + "&w=" + window.innerWidth + "&h=" + window.innerHeight;
                                const footerTxt = "<a href='" + data.data.links.html + "?utm_source=winterly.app&utm_medium=referral' target='_blank'>📷 Photo</a> · <a href='" + data.data.user.links.html + "?utm_source=winterly.app&utm_medium=referral' target='_blank'>" + data.data.user.name + "</a> · <a href='https://unsplash.com/?utm_source=winterly.app&utm_medium=referral' target='_blank'>Unsplash</a> · <a href='download/" + downloadUrl + "' target='_blank' >Download</a>";
                                localStorage.setItem("winterly-background", bgImage);
                                localStorage.setItem("winterly-footerTxt", footerTxt);
                                if (bgImage !== this.state.bgImage) {
                                    this.setState({ bgImage: bgImage, footerTxt: footerTxt });
                                }
                            })
                        }
                    } else {
                        let imgURL = "https://images.unsplash.com/photo-1510798831971-661eb04b3739?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&w=" + window.innerWidth + "&h=" + window.innerHeight;
                        if (window.innerWidth > window.innerHeight) {
                            imgURL = "https://images.unsplash.com/photo-1455156218388-5e61b526818b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&w=" + window.innerWidth + "&h=" + window.innerHeight;
                        }
                        this.setState({
                            bgImage: imgURL
                        })
                    }
                }).catch(err => { });

        } catch (e) {
        }
    }

    componentDidMount() {
        if (isValidURL(initialBackground) && (initialFooterTxt !== null)) {
            this.setState({
                bgImage: initialBackground,
                footerTxt: initialFooterTxt
            })
        } else {
            this.loadData();
        }
        this.themeChanged();
        if (typeof document !== 'undefined') {
            document.addEventListener('themeChanged', this.themeChanged)
        }
        setInterval(this.loadData,
            300000
            // 60000
        );
    }

    render() {
        const isHome = this.props.isHome;
        return (
            <>
                {(isHome) ?
                    <div className="homeBackground" style={{
                        backgroundImage: "linear-gradient( rgba(0, 0, 0," + this.state.darkenBackground + "), rgba(0, 0, 0," + this.state.darkenBackground + ") ), url(" + this.state.bgImage + ")",
                        backgroundSize: "cover"
                    }}></div> :
                    <div className="hero-image" style={{
                        backgroundImage: "linear-gradient( rgba(0, 0, 0," + this.state.darkenBackground + "), rgba(0, 0, 0," + this.state.darkenBackground + ") ), url(" + this.state.bgImage + ")",
                    }}></div>
                }
                <Footer
                    transparentBacground={this.props.isHome}
                    credit={this.state.footerTxt}
                />
            </>
        );
    }
}

export default BackgroundImage;