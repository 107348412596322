import { openDB } from 'idb';
import Moment from 'moment';
// import * as idb from 'idb';

const IDB_NAME = "winterly-app";
const IDB_VERSION = 1;
const IDB_STORE_STORY = "stories";

const idb = {};

export async function createNewDB() {
  openDB(IDB_NAME, IDB_VERSION, {
    upgrade(db) {
      db.createObjectStore(IDB_STORE_STORY);
      idb.db = openDB(IDB_NAME, IDB_VERSION)
    },
  });
}

export async function saveStories(id, content) {
  if (!("db" in idb)) idb.db = openDB(IDB_NAME, IDB_VERSION);
  let rerval = "";
  const result = (await idb.db).add(IDB_STORE_STORY, content, id);
  rerval = await result.then((value) => {
    try {
      let id = parseInt(value)
      if (!isNaN(id)) {
        return Moment(id).isValid();
      }
      else return false;

    } catch (error) {
      return false;
    }
  })
  return rerval;
}

export async function updateStories(id, content) {
  if (!("db" in idb)) idb.db = openDB(IDB_NAME, IDB_VERSION);
  let rerval = "";
  const result = (await idb.db).put(IDB_STORE_STORY, content, id);
  rerval = await result.then((value) => {
    try {
      let id = parseInt(value)
      if (!isNaN(id)) {
        return Moment(id).isValid();
      }
      else return false;

    } catch (error) {
      return false;
    }
  })
  return rerval;
}

export async function getAllStories() {
  if (!("db" in idb)) idb.db = openDB(IDB_NAME, IDB_VERSION);
  const result = (await idb.db).getAll(IDB_STORE_STORY).then((value) => {
    return value;
  });
  return result;
}

export async function getStory(id) {
  if (!("db" in idb)) idb.db = openDB(IDB_NAME, IDB_VERSION);
  const result = (await idb.db).get(IDB_STORE_STORY, id).then((value) => {
    return value;
  });
  return result;
}

export async function deleteStory(id) {
  if (!("db" in idb)) idb.db = openDB(IDB_NAME, IDB_VERSION);
  const result = (await idb.db).delete(IDB_STORE_STORY, String(id)).then((value) => {
    return value;
  });
  return result;
}