import React, { Component } from 'react';

import Snackbar from '@material-ui/core/Snackbar';

class PopupMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showNotification: this.props.showNotification,
        }
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({
            showNotification: !(this.state.showNotification),
        });

    };

    componentDidUpdate(prevProps) {
        if (this.props.showNotification !== prevProps.showNotification) {
            this.setState({
                showNotification: this.props.showNotification
            })
        }
    }

    render() {
        const popupClass = (this.props.isAlert) ? "popNotif popAlert" : "popNotif";
        return (
            <div>
                <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    open={this.state.showNotification}
                    autoHideDuration={5000}
                    onClick={this.handleClose}
                >
                    <div className={popupClass} onClick={this.handleClose}>{this.props.message}</div>
                </Snackbar>
            </div>
        );
    }
}

export default PopupMessage;