import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';

import {countMinToRead} from '../../util/Helper';
import { getAllStories } from '../../util/IndexedDB';
import BackgroundImage from '../../components/BackgroundImage';

class Stories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            noteData: [],
            emptyStory: "",
            tempStory: "",
            searchStory: [],
            hrDisplay: "block",
            backToHome: false
        };
        this.loadNoteData = this.loadNoteData.bind(this);
        this.searchStory = this.searchStory.bind(this);
        this.handleBackButton = this.handleBackButton.bind(this);
    }

    handleBackButton() {
        this.setState({
            backToHome: true
        })
    }

    componentDidMount() {
        this.loadNoteData();
    }

    searchStory(e) {
        if ((e.target.value !== "") && (e.target.value !== undefined) && (e.target.value !== null)) {
            let tempStory = this.state.noteData;
            let tempFound = [];
            for (let i = 0; i < tempStory.length; i++) {
                if ((tempStory[i].text).toLowerCase().includes((e.target.value).toLowerCase())) {
                    tempFound.push(tempStory[i]);
                } else if ("title" in tempStory[i]) {
                    if ((tempStory[i].title).toLowerCase().includes((e.target.value).toLowerCase())) {
                        tempFound.push(tempStory[i]);
                    }
                }
            }
            this.setState({
                searchStory: tempFound
            })
        } else {
            this.setState({
                searchStory: this.state.noteData
            })
        }
    }

    async loadNoteData() {
        const storiesData = await getAllStories();
        // Sort by lastUpdate
        storiesData.sort((a, b) => {
            return a.lastUpdate - b.lastUpdate;
        });
        storiesData.reverse();
        const emptyStory = (storiesData.length === 0) ? "This plain blank page is missing your stories :( \nWhat about start writing one?" : "";
        const hrDisplay = (emptyStory !== "") ? "none" : "block";
        this.setState({
            noteData: storiesData,
            emptyStory: emptyStory,
            searchStory: storiesData,
            hrDisplay: hrDisplay
        })

    }

    render() {
        if (this.state.backToHome) {
            return (
                <Redirect to="/" />
            )
        }

        return (
            <div className="pageContainerStories">
                <button className="backHomeButton" onClick={this.handleBackButton}>← Back</button>
                <BackgroundImage />
                <div className="pageContentStories">
                    <div className="writeBtnDiv">
                        <Link to="/stories/new"><button className="writeButtonStory">+ Write Story</button></Link>
                        <input
                            spellCheck={false}
                            autoComplete="off"
                            type="search"
                            id="site-search"
                            name="q"
                            placeholder="Search your stories..."
                            onChange={this.searchStory}
                        />
                    </div>
                    {/* <hr style={{width:"85vw", display: this.state.hrDisplay}} /> */}
                    <Grid className="noteContainer" item>
                        <Grid container justify="center" spacing={2}>
                            <p className="emptyStoryText">{this.state.emptyStory}</p>
                            {(this.state.searchStory).map((value) => (
                                <Grid key={value.id} item>
                                    <Paper elevation={0}>
                                        <div className="paperStory" >
                                            <Link to={"/stories/show/" + value.id}><div className="paperStoryTitle">{(value.title)}</div></Link>                                            <div className="paperStoryContent">{(value.excerpt)}</div>
                                            <div className="paperStoryMeta">Last edited {moment(value.lastUpdate).fromNow()} · {countMinToRead(value.words)} min read</div>
                                        </div>
                                    </Paper>
                                    {/* <hr /> */}
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <p>&nbsp;</p>
                </div>
            </div >
        );
    }
}

export default Stories;
