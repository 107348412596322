import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import Faq from 'react-faq-component';

import BackgroundImage from '../../components/BackgroundImage';

const faqData = {
    rows: [
      {
        title: "What is Winterly?",
        content: "Winterly is beautiful winter-themed personal dashboard for your web browser that you can use to save all your writings in a super <em>easy-to-access</em> place. No signup required to use Winterly."
      },
      {
          title: "What can I write in Winterly?",
          content: "It's up to you. You can use Winterly to save a quick & temporary notes before you move it to another app of your choice, or you can also use Winterly for a long writing session."
      },
      {
          title: "Why should I use Winterly?",
          content: "Winterly provides you with a <em>minimalist but powerful</em> user interface for story writing, making sure that you are not distracted by other things while you are working on your stories."
      },
      {
          title: "Where are my stories stored?",
          content: "Your stories are stored locally (internal database) in your browser, so you own 100% of your contents. Just make sure that you don't clear your browser's data before backing up your stories, or your stories will be permanently deleted."
      },
      {
          title: "I don't write that often, how do I use Winterly?",
          content: "If you don't write that often, you can still enjoy the Winterly with its beautiful background and quote in the homepage (that's why it is called personal dashboard). The background will be regularly changed every 5 minutes, while the quote will be regularly changed every minute."
      },
      {
          title: "I have another question",
          content: "If you have any other question or just want to say hello, drop your message to <a href='mailto:winterly.app@gmail.com'>Winterly.App@gmail.com</a>."
      }
    ]
  }

class FAQ extends Component {
    constructor(props) {
        super(props);
        this.state = {
            backToHome: false
        };
        this.handleBackButton = this.handleBackButton.bind(this);
    }

    handleBackButton() {
        this.setState({
            backToHome: true
        })
    }

    
    render() {
        if (this.state.backToHome) {
            return (
                <Redirect to="/" />
            )
        }
        return (
            <div className="pageContainer">
                <button className="backHomeButton" onClick={this.handleBackButton}>← Back</button>
                <BackgroundImage />
                <div className="pageContent">
                    <h1 style={{ fontSize: "50px" }}>FAQs</h1>
                    {/* <p style={{color: "#ababab"}}>... waiting for the snow ❄ to melt to continue this page construction</p> */}
                    <Faq data={faqData}/>
                    <p>&nbsp;</p>
                </div>
            </div >
        );
    }
}

export default FAQ;
