import React, { Component } from 'react';

import { URL_SEED_GROUP } from '../../util/Helper';
import SyncProcess from '../SyncProcess';

var originalSetItem = localStorage.setItem;
localStorage.setItem = function () {
    var event = new Event('syncCode');
    document.dispatchEvent(event);
    originalSetItem.apply(this, arguments);
}

class SyncEnterCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cloneParent: false,
            createSyncCode: false,
            syncCode: "",
            disableInput: false,
            wrongCode: false,
            correctCode: false
        }
        this.handleCreateSyncCode = this.handleCreateSyncCode.bind(this);
        this.handleCreateSyncButton = this.handleCreateSyncButton.bind(this);
        this.resetView = this.resetView.bind(this);
        this.requestSync = this.requestSync.bind(this);
    }

    requestSync() {
        if (this.state.syncCode !== "") {
            this.setState({
                disableInput: true,
                wrongCode: false
            }, () => {
                const url = URL_SEED_GROUP + this.state.syncCode;
                fetch(url)
                    .then(data => data.json())
                    .then(data => {
                        if ("success" in data) {
                            this.setState({
                                wrongCode: !data.success,
                                disableInput: false,
                                correctCode: data.success
                            }, () => {
                                if (data.success) {
                                    localStorage.setItem("winterly-seed", data.seed);
                                    localStorage.setItem("winterly-id", data.id);
                                    localStorage.setItem("winterly-uid", data.uid);
                                }
                            })
                        }
                    });
            })
        }
    }

    resetView() {
        this.setState({
            cloneParent: false,
            createSyncCode: false
        })
    }

    handleCreateSyncButton() {
        this.setState({
            createSyncCode: true
        })
    }

    handleCreateSyncCode() {
        this.setState({
            cloneParent: true
        })
    }

    render() {
        if (!this.state.cloneParent && !this.state.createSyncCode) {

            return (
                <div>
                    <form onSubmit={(e) => { e.preventDefault(); }}>
                        <br />
                        <label htmlFor="enterCodeSync">Enter your sync code below:</label><br />
                        <input className="settingsInputField" id="enterCodeSync" autoComplete="off" required autoFocus placeholder="Your sync code here..." onChange={(e) => { this.setState({ syncCode: e.target.value, wrongCode: false, correctCode: false }) }} value={this.state.syncCode} disabled={this.state.disableInput}></input>
                        {
                            (this.state.disableInput) ?
                                <svg width="15px" height="15px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                    <g>
                                        <animateTransform attributeName="transform" type="rotate" values="0 33 33;270 33 33" begin="0s" dur="1.4s" fill="freeze" repeatCount="indefinite" />
                                        <circle fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" strokeDasharray="187" strokeDashoffset="610">
                                            <animate attributeName="stroke" values="#4285F4;#DE3E35;#F7C223;#1B9A59;#4285F4" begin="0s" dur="5.6s" fill="freeze" repeatCount="indefinite" />
                                            <animateTransform attributeName="transform" type="rotate" values="0 33 33;135 33 33;450 33 33" begin="0s" dur="1.4s" fill="freeze" repeatCount="indefinite" />
                                            <animate attributeName="stroke-dashoffset" values="187;46.75;187" begin="0s" dur="1.4s" fill="freeze" repeatCount="indefinite" />
                                        </circle>
                                    </g>
                                </svg>
                                :
                                (this.state.wrongCode) ?
                                    <span role="img" aria-label="failed">❌</span>
                                    :
                                    (this.state.correctCode) ?
                                        <span role="img" aria-label="failed">✔</span>
                                        :
                                        ""
                        }
                        <br />
                        <br />
                        <button className="saveStoryButton" style={{ float: "left", marginTop: "0px" }} onClick={this.requestSync}>Sync This Browser</button>
                        <br />
                        <br />
                        <br />
                    </form>
                    <p>Don't have a code? <button className="createSyncCodeSettings" onClick={this.handleCreateSyncCode}>Create a sync code</button> instead.</p>
                </div>
            );
        }
        else if (this.state.cloneParent && !this.state.createSyncCode) {
            return (
                <div>
                    <button className="saveStoryButton" onClick={this.resetView}>Enter a code</button>
                    <button className="saveStoryButton" onClick={this.handleCreateSyncButton}>Sync stories</button>
                </div>
            )
        }
        else if (this.state.cloneParent && this.state.createSyncCode) {
            return (
                <SyncProcess />
            )
        }
    }
}

export default SyncEnterCode;