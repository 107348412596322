import React, { Component } from 'react';
import { Link } from "react-router-dom";

import Clock from '../../components/Clock';
import Quote from '../../components/Quote';
import BackgroundImage from '../../components/BackgroundImage';
import Greetings from '../../components/Greetings';

class Home extends Component {
    render() {
        return (
            <div className="App">
                <BackgroundImage
                    isHome={true}
                />
                <div className="writeBtnDiv">
                    <Link to="/stories"><button className="writeButton">+ Your Stories</button></Link>
                </div>
                <div className="App-header">
                    <div className="homeContent">
                        <Clock className="clock" />
                        <Greetings />
                        <Quote />
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
