import React, { Component } from 'react';

const GREETING_TIME_NEUTRAL = [
    "Good day",
    "Welcome back",
    "It's good to see you again",
    "Howdy",
    "G'day",
    "Hey",
    "Yo",
    "Bonjour",
    "Hola",
    "Здравствуйте",
    "Ahoy"
]

const GREETING_MORNING = [
    "Good morning",
    "Morning"
]

const GREETING_AFTERNOON =[
    "Good afternoon"
]

const GREETING_EVENING = [
    "Good evening"
]

class Greetings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            greetingTxt: "",
            name: "",
            isName: false
        }
        this.checkTime = this.checkTime.bind(this);
        this.saveName = this.saveName.bind(this);
    }

    saveName(e) {
        if ((e.keyCode === 13) && (e.target.value !== "")) {
            e.preventDefault();
            const name = e.target.value;
            this.setState({
                name: name,
                isName: true
            }, () => {
                localStorage.setItem("winterly-name", name);
                this.checkTime();
            })
        }
    }

    checkTime() {
        const today = new Date();
        const curHr = today.getHours();
        let greetingList = [];

        if (curHr < 12) {
            greetingList = GREETING_TIME_NEUTRAL.concat(GREETING_MORNING);
        }
        else if (curHr < 18) {
            greetingList = GREETING_TIME_NEUTRAL.concat(GREETING_AFTERNOON);
        }
        else {
            greetingList = GREETING_TIME_NEUTRAL.concat(GREETING_EVENING);
        }

        const greetingTxt = greetingList[Math.floor(Math.random() * greetingList.length)] + ", " + this.state.name + "!";

        this.setState({
            greetingTxt: greetingTxt
        })
    }

    componentDidMount() {
        const initialName = (localStorage.getItem("winterly-name") !== null) ? localStorage.getItem("winterly-name") : "";
        this.setState({
            name: initialName,
            isName: (initialName === "") ? false : true
        }, () => {
            this.checkTime();
        })

    }

    render() {
        return (
            <div className="homeGreetings">
                {
                    (this.state.isName) ?
                        <p>{this.state.greetingTxt}</p> :
                        <p>What should I call you? <input className="nameGreetingsInput" spellCheck={false} autoComplete="off" onKeyDown={this.saveName} maxLength={15}></input> </p>
                }
            </div>
        );
    }
}

export default Greetings;