import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            credit: "",
            footerID: "footerTransparent"
        }
    }

    componentDidMount() {
        const footerID = (this.props.transparentBacground) ? "footerTransparent" : "footerWhite";
        this.setState({
            credit: this.props.credit,
            footerID: footerID
        })
    }

    componentWillReceiveProps(nextProps) {
        const footerID = (this.props.transparentBacground) ? "footerTransparent" : "footerWhite";
        if (this.props.credit !== nextProps.credit) {
            this.setState({
                credit: nextProps.credit,
                footerID: footerID
            })
        }
    }

    render() {
        return (
            <div className={"footerPage " + this.state.footerID}>
                <div className={"left " + this.state.footerID} dangerouslySetInnerHTML={{ __html: this.state.credit }} />
                <div className={"right " + this.state.footerID}><Link to="/">Home</Link> · <Link to="/about">About</Link> · <Link to="/faq">FAQ</Link> · <Link to="/settings">Settings</Link> · Made with ❤ by <a href="https://nightbot.dev" target="_blank" rel="noopener noreferrer">@dkhd</a></div>
            </div>
        );
    }
}

export default Footer;