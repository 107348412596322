import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";

import BackgroundImage from '../../components/BackgroundImage';

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            backToHome: false
        };
        this.handleBackButton = this.handleBackButton.bind(this);
    }

    handleBackButton() {
        this.setState({
            backToHome: true
        })
    }

    render() {
        if (this.state.backToHome) {
            return (
                <Redirect to="/" />
            )
        }
        return (
            <div className="pageContainer" >
                <button className="backHomeButton" onClick={this.handleBackButton}>← Back</button>
                <BackgroundImage />
                <div className="pageContent">
                    <h1 className="pageTitleCustom">About</h1>
                    <p>Winterly is a beautiful winter-themed personal dashboard for your web browser, aimed to replace your old-fashioned New Tab page.</p>
                    <div style={{
                        color: "#4d4d4d",
                        fontStyle: "italic",
                        marginTop: "10px",
                        marginBottom: "10px",
                        marginLeft: "25px",
                        paddingLeft: "15px",
                        borderLeft: "3px solid #ccc"
                    }}>We write to taste life twice, in the moment and in retrospect.<br />― Anaïs Nin</div>
                    <p>Featuring the powerful & minimalist-looking text editor, Winterly is specially built for you to turn any ideas into beautiful stories. We provide you with the <em>easy-to-access</em> place to write.</p>
                    <p>Currently, Winterly is only available on the <Link to="/">web version</Link>, and the browser-extension version is currently still being planned.</p>
                    <p>If you have any other question or just want to say hello, drop your message to <a href="mailto:winterly.app@gmail.com">Winterly.App@gmail.com</a>.</p>
                    <p>Hope you enjoy it!</p>
                    <p>&nbsp;</p>
                </div>
            </div >
        );
    }
}

export default About;
