import React, { Component } from 'react';
import { Redirect, Prompt } from "react-router-dom";

import { DraftailEditor } from "draftail";
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';
import {
    ItalicButton,
    BoldButton,
    UnderlineButton,
} from 'draft-js-buttons';
import createHashtagPlugin from 'draft-js-hashtag-plugin';
import createLinkifyPlugin from 'draft-js-linkify-plugin';
import createSideToolbarPlugin from 'draft-js-side-toolbar-plugin';
import createEmojiPlugin from '@tunoltd/draft-js-emoji-plugin';

// import { Switch } from '@material-ui/core';
// import { withStyles } from '@material-ui/core/styles';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPencilAlt, faEye } from '@fortawesome/free-solid-svg-icons';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextareaAutosize from 'react-textarea-autosize';

import { saveStories, getStory, updateStories, deleteStory } from '../../util/IndexedDB';
import Footer from '../../components/Footer';

import "draft-js/dist/Draft.css";
import "draftail/dist/draftail.css";
import 'draft-js-inline-toolbar-plugin/lib/plugin.css';
import 'draft-js-hashtag-plugin/lib/plugin.css';
import 'draft-js-linkify-plugin/lib/plugin.css';
import 'draft-js-side-toolbar-plugin/lib/plugin.css';
import '@tunoltd/draft-js-emoji-plugin/lib/plugin.css';

import { isJsonEmpty } from '../../util/Helper';
import PopupMessage from '../../components/PopupMessage';

const inlineToolbarPlugin = createInlineToolbarPlugin();
const { InlineToolbar } = inlineToolbarPlugin;
const hashtagPlugin = createHashtagPlugin();
const linkifyPlugin = createLinkifyPlugin();
const sideToolbarPlugin = createSideToolbarPlugin();
const emojiPlugin = createEmojiPlugin({
    useNativeArt: true,
});

const { EmojiSuggestions } = emojiPlugin;

const { SideToolbar } = sideToolbarPlugin;


// const EditableSwitch = withStyles({
//     switchBase: {
//         color: "#fff",
//         '&$checked': {
//             color: "#fff",
//         },
//         '&$checked + $track': {
//             backgroundColor: "#333",
//         },
//     },
//     checked: {},
//     track: {},
// })(Switch);


class NewStory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editableTxt: false,
            editOpacity: 1,
            viewOpacity: 0.3,
            backToStory: false,
            showNotification: false,
            tempData: {}, // for content
            tempTitle: "", //for title
            doRender: false,
            isNewStory: true,
            storyID: 0,
            isAlert: false,
            notifText: "",
            showDeleteDialog: false,
            redirectNewStory: false,
            saveInterval: 0,
            savedStory: {},
            savedTitle: "",
            isBlocking: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.saveTempData = this.saveTempData.bind(this);
        this.handleDeleteClose = this.handleDeleteClose.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.deleteConfirmed = this.deleteConfirmed.bind(this);
        this.saveTempTitle = this.saveTempTitle.bind(this);
        this.handleBackButton = this.handleBackButton.bind(this);
        this.checkBlocking = this.checkBlocking.bind(this);
        this.setNotificationToFalse = this.setNotificationToFalse.bind(this);
    }

    checkBlocking() {
        this.setState({
            isBlocking: !((JSON.stringify(this.state.savedStory) === JSON.stringify(this.state.tempData)) && (this.state.savedTitle === this.state.tempTitle))
        }, () => {
            // console.log("");
            // console.log("isBlocking", this.state.isBlocking);
            // console.log("savedStory", JSON.stringify(this.state.savedStory));
            // console.log("tempData", JSON.stringify(this.state.tempData));
            // console.log("(savedStory = tempData)", (JSON.stringify(this.state.savedStory) === JSON.stringify(this.state.tempData)));
            // console.log("(savedTitle = tempTitle)", (this.state.savedTitle === this.state.tempTitle));
        })
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({
            showNotification: false,
        });

    };

    handleBackButton() {
        this.setState({
            backToStory: true
        })
    }

    handleDeleteClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({
            showDeleteDialog: false,
        });

    };

    saveTempData(content) {
        content = (content == null) ? {} : content;
        this.setState({ tempData: content, }, () => {
            this.checkBlocking();
        });
    }

    saveTempTitle(e) {
        this.setState({ tempTitle: e.target.value }, () => {
            this.checkBlocking();
        })
    }

    onDelete() {
        this.setState({
            showDeleteDialog: true
        });
    }

    async deleteConfirmed() {
        this.setState({ isBlocking: false }, async () => {
            if (this.state.isNewStory) {
                this.setState({
                    backToStory: true
                });
            } else {
                await deleteStory(this.state.storyID);
                this.setState({
                    showDeleteDialog: false,
                    backToStory: true
                })
            }
        })
    }

    async onSave() {
        if ((this.state.tempData !== "") && (this.state.tempData !== null) && (this.state.tempData !== undefined) && (!isJsonEmpty(this.state.tempData)) && (this.state.tempTitle !== "") && (this.state.tempTitle !== null) && (this.state.tempTitle !== undefined)) {
            if (this.state.isNewStory) {
                const id = Date.now();
                let words = "";
                for (let i = 0; i < (this.state.tempData).blocks.length; i++) {
                    words += ((this.state.tempData).blocks[i].text + " ");
                }
                const params = {
                    id: id,
                    type: "story",
                    excerpt: (words).split(" ").splice(0, 30).join(" "),
                    words: (words).split(' ').length,
                    text: words,
                    lastUpdate: Date.now(),
                    title: this.state.tempTitle,
                    body: this.state.tempData
                };
                const result = await saveStories(String(id), params);
                if (result) this.setState({
                    showNotification: true,
                    isAlert: false,
                    notifText: "Your story has been saved",
                    isNewStory: false,
                    storyID: id,
                    savedStory: this.state.tempData,
                    savedTitle: this.state.tempTitle
                }, () => {
                    this.checkBlocking();
                    this.setNotificationToFalse();
                });
            } else {
                const id = this.state.storyID;
                let words = "";
                for (let i = 0; i < (this.state.tempData).blocks.length; i++) {
                    words += ((this.state.tempData).blocks[i].text + " ");
                }
                const params = {
                    id: id,
                    type: "story",
                    excerpt: (words).split(" ").splice(0, 30).join(" "),
                    words: (words).split(' ').length,
                    text: words,
                    lastUpdate: Date.now(),
                    title: this.state.tempTitle,
                    body: this.state.tempData
                };
                const result = await updateStories(String(id), params);
                if (result) this.setState({
                    showNotification: true,
                    isAlert: false,
                    notifText: "Your story has been saved",
                    savedStory: this.state.tempData,
                    savedTitle: this.state.tempTitle
                }, () => {
                    this.checkBlocking();
                    this.setNotificationToFalse();
                });
            }
        } else if ((this.state.tempTitle === "") || (this.state.tempTitle === null) || (this.state.tempTitle === undefined)) {
            this.setState({
                showNotification: true,
                isAlert: true,
                notifText: "Cannot save story with empty title"
            }, () => {
                this.setNotificationToFalse();
            });
        }
        else {
            this.setState({
                showNotification: true,
                isAlert: true,
                notifText: "Cannot save empty story"
            }, () => {
                this.setNotificationToFalse();
            });
        }
    }

    setNotificationToFalse() {
        setInterval(() => {
            this.setState({
                showNotification: false
            })
        }, 5000);
    }

    async componentDidMount() {
        document.addEventListener("keydown", (e) => {
            if ((window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) && e.keyCode === 83) {
                e.preventDefault();
                this.onSave();
            }
        });

        const storyID = ((window.location.pathname).split('/')[(window.location.pathname).split('/').length - 1]);
        if (!isNaN(parseInt(storyID))) {
            const story = await (getStory(storyID));
            if ((story !== undefined) && (story !== "") && (story != null)) {
                this.setState({
                    savedStory: story.body,
                    initialTxt: story.body,
                    savedTitle: story.title,
                    tempTitle: story.title,
                    doRender: true,
                    isNewStory: false,
                    storyID: story.id
                })
            }
            else {
                this.setState({
                    redirectNewStory: true
                })
            }
        } else {
            this.setState({
                doRender: true,
                isNewStory: true
            })
        }
    }

    handleChange = (event) => {
        const editableString = (this.state.editableString === "Read only: off") ? "Read only: on" : "Read only: off";
        const editOpacity = (this.state.editOpacity === 1) ? 0.3 : 1;
        const viewOpacity = (this.state.viewOpacity === 1) ? 0.3 : 1;
        this.setState({
            [event.target.name]: event.target.checked,
            editableString: editableString,
            editOpacity: editOpacity,
            viewOpacity: viewOpacity
        });
    };

    render() {
        const backToStory = this.state.backToStory;

        if (this.state.redirectNewStory) {
            return (
                <Redirect to="/404" />
            )
        }

        if (this.state.doRender) {
            return (
                <div className="newStoryContainer">
                    {backToStory ? <Redirect to="/stories" /> : ""}
                    {/* Promp blocking */}
                    <Prompt
                        when={this.state.isBlocking}
                        message={(location, action) => {
                            this.setState({
                                backToStory: false
                            })
                            return "You have an unsaved story, are you sure want to leave?";
                        }

                        }
                    />

                    {/* Delete dialog */}
                    <Dialog
                        open={this.state.showDeleteDialog}
                        onClose={this.handleDeleteClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">Delete Story?</DialogTitle>
                        <DialogContent>
                            <DialogContentText className="deleteDialogText" id="alert-dialog-description">
                                This story will be permanently deleted and cannot be recovered.
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <button className="cancelStoryButton" onClick={this.handleDeleteClose}>
                                Cancel
                            </button>
                            <button className="deleteStoryButton" onClick={this.deleteConfirmed} autoFocus>
                                Delete
                            </button>
                        </DialogActions>
                    </Dialog>

                    {/* Notification snackbar */}
                    <PopupMessage
                        isAlert={this.state.isAlert}
                        showNotification={this.state.showNotification}
                        message={this.state.notifText}
                    />
                    {/* <div className="editableContainer">
                        <FormControlLabel
                            className="EditableSwitch"
                            control={<EditableSwitch checked={this.state.editableTxt} onChange={this.handleChange} name="editableTxt" />}
                        />
                        <FontAwesomeIcon className="editableIcon" icon={faPencilAlt} style={{ opacity: this.state.editOpacity }} />
                        <FontAwesomeIcon className="editableIconRight" icon={faEye} style={{ opacity: this.state.viewOpacity }} />
                    </div> */}
                    <div>
                        <button className="backStoryButton" onClick={this.handleBackButton}>← Back</button>
                        <button className="saveStoryButton" onClick={this.onSave}>Save</button>
                        <button className="deleteStoryButton" onClick={this.onDelete}>Delete</button>
                    </div>
                    <TextareaAutosize
                        className="newStoryTitleField"
                        onChange={this.saveTempTitle}
                        value={this.state.tempTitle}
                        placeholder="Story title"
                        spellCheck={false}
                        readOnly={this.state.editableTxt}
                    />
                    <DraftailEditor
                        stateSaveInterval={this.state.saveInterval}
                        rawContentState={this.state.initialTxt}
                        onSave={this.saveTempData}

                        plugins={[inlineToolbarPlugin, linkifyPlugin, hashtagPlugin, sideToolbarPlugin, emojiPlugin]}
                        placeholder="What's your story?"
                        readOnly={this.state.editableTxt}

                        stripPastedStyles={false}
                        topToolbar={null}
                    />
                    <InlineToolbar>
                        {
                            (externalProps) => (
                                <div>
                                    <BoldButton {...externalProps} />
                                    <ItalicButton {...externalProps} />
                                    <UnderlineButton {...externalProps} />
                                </div>
                            )
                        }
                        {/* DraftJS plugins render here */}
                    </InlineToolbar>
                    <SideToolbar />
                    <EmojiSuggestions />

                    <Footer />
                </div >
            );
        }
        else {
            return (
                <></>
            )
        }
    }
}

export default NewStory;
