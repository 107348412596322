import React, { Component } from 'react';
import { Redirect } from "react-router-dom";

import BackgroundImage from '../../components/BackgroundImage';

class NotFound extends Component {
    constructor(props) {
        super(props);
        this.state = {
            backToHome: false
        };
        this.handleBackButton = this.handleBackButton.bind(this);
    }

    handleBackButton() {
        this.setState({
            backToHome: true
        })
    }

    render() {
        if (this.state.backToHome) {
            return (
                <Redirect to="/" />
            )
        }
        return (
            <div className="pageContainer">
                <button className="backHomeButton" onClick={this.handleBackButton}>← Back</button>
                <BackgroundImage />
                <div className="pageContent">
                    <h1 style={{ fontSize: "50px", lineHeight: "1.5em" }}>404 — Not Found</h1>
                    <p>Oops.. It seems that the blizzard brings you here.</p>
                    <p>How about we go back home, drink a cup of hot chocolate, and do some writings?</p>
                    <p>&nbsp;</p>
                </div>
            </div >
        );
    }
}

export default NotFound;
