let URL_BACKGROUND = "";
let URL_QUOTE = "";
let URL_GET_BLOG = "";
let URL_SEED_GROUP = "";
let URL_BACKEND = "";
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  URL_BACKGROUND = 'http://localhost:5000/wallpaper?orientation=';
  URL_QUOTE = "http://localhost:5000/quote/";
  URL_GET_BLOG = "http://localhost:5000/blog/";
  URL_SEED_GROUP = "http://localhost:5000/seed/"
  URL_BACKEND = "http://localhost:5000/"
} else {
  // production code
  URL_BACKGROUND = 'https://winterly-backend.herokuapp.com/wallpaper?orientation=';
  URL_QUOTE = "https://winterly-backend.herokuapp.com/quote/";
  URL_GET_BLOG = "https://winterly-backend.herokuapp.com/blog/";
  URL_SEED_GROUP = "https://winterly-backend.herokuapp.com/seed/";
  URL_BACKEND = "https://winterly-backend.herokuapp.com/";
}

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

function isJsonEmpty(obj) {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

function countMinToRead(text) {
  return Math.round(text / 200);
}

async function getAllBlog(id) {
  const url = (id != null) ? URL_GET_BLOG + id : URL_GET_BLOG;
  let result = [];
  try {
    result = await fetch(url)
      .then(response => response.json())
      .then(data => {
        return data;
      })
  } catch (error) {
    return [];
  }
  return result;
}

function detectOSColorScheme() {
  const color = (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) ? "dark" : "light";
  return color;
}

function isValidURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
}

function getSyncCode() {
  const isSynced = ((localStorage.getItem("winterly-id") != null) && (localStorage.getItem("winterly-seed") != null))
  const syncCode = (isSynced) ? localStorage.getItem("winterly-seed") + "-" + (localStorage.getItem("winterly-id")).substr(localStorage.getItem("winterly-id").length - 5) : "";
  return syncCode;
}

const CLOCK_FORMAT = [
  "HH:mm",
  "hh:mm A",
  "MMM DD, YYYY [\r\n] HH:mm",
  "MMM DD, YYYY [\r\n] hh:mm A",
  "DD MMM YYYY [\r\n] HH:mm",
  "DD MMM YYYY [\r\n] hh:mm A",
  "DD/MM/YYYY [\r\n] HH:mm",
  "DD/MM/YYYY [\r\n] hh:mm A",
]

export {
  URL_BACKGROUND,
  URL_QUOTE,
  URL_GET_BLOG,
  URL_SEED_GROUP,
  URL_BACKEND,
  isJsonString,
  isJsonEmpty,
  countMinToRead,
  getAllBlog,
  detectOSColorScheme,
  isValidURL,
  getSyncCode,
  CLOCK_FORMAT
}