import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import io from 'socket.io-client';

import withTracker from './components/Tracker';
import { createNewDB } from './util/IndexedDB';
import { detectOSColorScheme } from './util/Helper';
import PopupMessage from './components/PopupMessage';

import Home from './pages/Home';
import About from './pages/About';
import NotFound from './pages/NotFound';
import Stories from './pages/Stories';
import NewStory from './pages/NewStory';
import FAQ from './pages/FAQ';
// import PublicPost from './pages/PublicPost';
import Blog from './pages/Blog';
import ViewStory from './pages/ViewStory';
import Privacy from './pages/Privacy';
import Download from './pages/Download';
import Settings from './pages/Settings';

import './themes/main.css';

import { URL_BACKEND, getSyncCode } from './util/Helper';

const theme = {
  "winter": {
    "main-color": "#4499c7",
    "app-background": "#fff",
    "main-text-color": "#121212",
    "main-text-color-inverted": "#fff",
    "border-color": "var(--main-text-color-inverted)",
    "placeholder-color": "#868686",
    "draftail-code-block": "#e9e9e9",
    "delete-button-color": "#ff81a5",
    "back-button-color": "#868686"
  },
  "winter-night": {
    "main-color": "#256C8E",
    "app-background": "#121212",
    "main-text-color": "#ababab",
    "main-text-color-inverted": "#ababab",
    "border-color": "var(--main-text-color-inverted)",
    "placeholder-color": "#868686",
    "draftail-code-block": "var(--app-background)",
    "delete-button-color": "#923a53",
    "back-button-color": "#868686"
  },
  "pure-dark": {
    "main-color": "#143e52",
    "app-background": "#000",
    "main-text-color": "#6A6969",
    "main-text-color-inverted": "#6A6969",
    "border-color": "var(--main-text-color-inverted)",
    "placeholder-color": "#868686",
    "draftail-code-block": "var(--app-background)",
    "delete-button-color": "#923a53",
    "back-button-color": "#868686"
  }
}

var originalSetItem = localStorage.removeItem;
localStorage.removeItem = function () {
  var event = new Event('syncCode');
  document.dispatchEvent(event);
  originalSetItem.apply(this, arguments);
}

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      theme: "",
      showNotification: false,
      notificationMessage: "",
      isAlert: false,
    }
    this.themeChanged = this.themeChanged.bind(this);
    this.setNotificationToFalse = this.setNotificationToFalse.bind(this);
    this.buildSocketConnection = this.buildSocketConnection.bind(this);
  }

  buildSocketConnection() {
    const seed = getSyncCode();
    if (seed !== "") {
      setTimeout(() => {
        const uid = localStorage.getItem("winterly-uid") || "";
        const socket = io(URL_BACKEND);
        socket.on('connect', () => {
          socket.emit("init", {
            seed: seed,
            uid: uid
          });
        });

        // Sync done, you're kicked. Delete data if not valid
        socket.on('isSyncValid', (data) => {
          if ("valid" in data) {
            if (!data.valid) {
              socket.disconnect();
              localStorage.removeItem("winterly-id");
              localStorage.removeItem("winterly-seed");
              localStorage.removeItem("winterly-uid");
              this.setState({
                showNotification: true,
                isAlert: true,
                notificationMessage: "This browser is not synced anymore"
              }, () => {
                // this.setNotificationToFalse();
              })
            }
          }
        })

      }, 0);
    }
  }

  themeChanged() {
    setTimeout(() => {
      const scheme = localStorage.getItem("winterly_color_scheme");
      const currentTheme = theme[scheme];
      Object.keys(currentTheme).forEach((key) => {
        const cssKey = "--" + key;
        const cssValue = currentTheme[key];
        document.body.style.setProperty(cssKey, cssValue);
      });
    }, 0);
  }


  setNotificationToFalse() {
    setTimeout(() => {
      this.setState({
        showNotification: false
      }, () => {
      })
    }, 5000);
  }


  componentDidMount() {
    // Create realtime connection with backend
    this.buildSocketConnection();

    if (localStorage.getItem("winterly_color_scheme") == null) {
      const color = (detectOSColorScheme() === "dark") ? "winter-night" : "winter";
      localStorage.setItem("winterly_color_scheme", color);
      this.setState({ theme: color });
    }
    createNewDB();
    this.themeChanged();

    if (typeof document !== 'undefined') {
      document.addEventListener('themeChanged', this.themeChanged)
      document.addEventListener('syncCode', this.buildSocketConnection)
    }

    // window.addEventListener('offline', () => {
    //   let condition = navigator.onLine ? "online" : "offline";
    //   if (condition === "offline") {
    //     this.setState({
    //       showNotification: true
    //     }, () => {
    //       this.setNotificationToFalse();
    //     })
    //   }
    // });
  }

  render() {
    return (
      <Router>
        {
          (this.state.showNotification) ?
            <PopupMessage
              showNotification={this.state.showNotification}
              isAlert={this.state.isAlert}
              message={this.state.notificationMessage}
            />
            :
            <></>
        }
        <Switch>
          <Route path="/" exact component={withTracker(Home)}></Route>
          <Route path="/about" exact component={withTracker(About)}></Route>
          <Route path="/stories" exact component={withTracker(Stories)}></Route>
          <Route path="/stories/new" exact component={withTracker(NewStory)}></Route>
          <Route path="/stories/show/:id" exact component={withTracker(NewStory)}></Route>
          <Route path="/faq" exact component={withTracker(FAQ)}></Route>
          {/* <Route path="/public" exact component={withTracker(PublicPost)}></Route> */}
          <Route path="/privacy" exact component={withTracker(Privacy)}></Route>
          <Route path="/@Winterly" exact component={withTracker(Blog)}></Route>
          <Route path="/@Winterly/:id" exact component={withTracker(ViewStory)}></Route>
          <Route path="/download/:photoid" exact component={withTracker(Download)}></Route>
          <Route path="/settings" exact component={withTracker(Settings)}></Route>

          <Route component={withTracker(NotFound)}></Route>
        </Switch>
      </Router>
    );
  }
}
export default App;
