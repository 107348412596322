import React, { Component } from 'react';

import { URL_SEED_GROUP } from '../../util/Helper';
import { Grid, Paper } from '@material-ui/core';

class SyncDeviceList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deviceRetrieved: false,
            deviceList: [],
            deletingDevice: ""
        }
        this.handleRemoveButton = this.handleRemoveButton.bind(this);
        this.formatDeviceName = this.formatDeviceName.bind(this);
        this.loadData = this.loadData.bind(this);
    }

    handleRemoveButton(uid) {
        const url = URL_SEED_GROUP + this.props.syncCode + "?delete=true&uid=" + uid;
        this.setState({
            deletingDevice: uid
        }, () => {
            fetch(url)
                .then(data => data.json())
                .then(data => {
                    this.formatDeviceName(data);
                })
        })
    }

    formatDeviceName(data) {
        if ("success" in data) {
            if (data.success) {
                if (Array.isArray(data.data) && ((data.data).length === 1)) {
                    let deviceList = data.data[0].members;
                    for (let i = 0; i < deviceList.length; i++) {
                        let deviceName = "";
                        const thisDevice = (localStorage.getItem("winterly-uid") === deviceList[i].uid) ? " (This browser)" : "";
                        if ((deviceList[i].ua.device.type === "mobile") || (deviceList[i].ua.device.type === "tablet")) {
                            deviceName = deviceList[i].ua.browser.name + " on " + deviceList[i].ua.device.vendor + " " + deviceList[i].ua.device.model + " (" + deviceList[i].ua.os.name + ")" + thisDevice;
                        } else {
                            deviceName = deviceList[i].ua.browser.name + " on " + deviceList[i].ua.os.name + " " + deviceList[i].ua.os.version + thisDevice;
                        }

                        deviceList[i].deviceName = deviceName;
                        deviceName = "";
                    }
                    this.setState({
                        deviceList: deviceList,
                        deviceRetrieved: true,
                        deletingDevice: ""
                    })
                }
            }
        }
    }

    loadData(isEvent=false) {
        const url = URL_SEED_GROUP + this.props.syncCode + "?list=true";
        fetch(url)
            .then(data => data.json())
            .then(data => {
                this.formatDeviceName(data);
            })
    }

    componentDidMount() {
        this.loadData();
        if (typeof document !== 'undefined') {
            document.addEventListener('newSyncMember', this.loadData(true))
          }
    }

    render() {
        if (this.state.deviceRetrieved) {
            return (
                <div className="deviceListContainer">
                    <Grid className="deviceGridContainer">
                        {/* <p>Devices:</p> */}
                        {(this.state.deviceList).map((value) => (
                            <Grid key={value.uid} item>
                                <Paper elevation={0}>
                                    <div className="paperDeviceList" >
                                        <div className="deviceNameSync">
                                            {(value.deviceName)}
                                        </div>
                                        <div className="actionDeviceNameSync">
                                            <button className="deleteStoryButton removeDeviceSync" onClick={() => this.handleRemoveButton(value.uid)}>Remove</button>
                                            {
                                                (String(this.state.deletingDevice) === String(value.uid)) ?
                                                    <svg className="svgLoaderRemoveDeviceSync" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                                        <g>
                                                            <animateTransform attributeName="transform" type="rotate" values="0 33 33;270 33 33" begin="0s" dur="1.4s" fill="freeze" repeatCount="indefinite" />
                                                            <circle fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" strokeDasharray="187" strokeDashoffset="610">
                                                                <animate attributeName="stroke" values="#4285F4;#DE3E35;#F7C223;#1B9A59;#4285F4" begin="0s" dur="5.6s" fill="freeze" repeatCount="indefinite" />
                                                                <animateTransform attributeName="transform" type="rotate" values="0 33 33;135 33 33;450 33 33" begin="0s" dur="1.4s" fill="freeze" repeatCount="indefinite" />
                                                                <animate attributeName="stroke-dashoffset" values="187;46.75;187" begin="0s" dur="1.4s" fill="freeze" repeatCount="indefinite" />
                                                            </circle>
                                                        </g>
                                                    </svg>
                                                    :
                                                    <div className="svgLoaderRemoveDeviceSync"></div>
                                            }
                                        </div>
                                    </div>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                </div>
            );
        } else return (<></>)
    }
}

export default SyncDeviceList;