import React, { Component } from 'react';

import { URL_SEED_GROUP, isJsonEmpty,getSyncCode } from '../../util/Helper';

var originalSetItem = localStorage.setItem;
localStorage.setItem = function () {
    var event = new Event('syncCode');
    document.dispatchEvent(event);
    originalSetItem.apply(this, arguments);
}

class SyncProcess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            getSeed: false,
            mainMessage: "",
            syncCode: "",
            syncID: "",
            cloneParent: false
        }
        this.handleUnsyncButton = this.handleUnsyncButton.bind(this);
        this.loadData = this.loadData.bind(this);
    }

    loadData() {
        this.setState({
            getSeed: false,
            cloneParent: false,
            mainMessage: "Please wait, we're setting things up for you...",
        }, () => {
            fetch(URL_SEED_GROUP)
                .then(data => data.json())
                .then(data => {
                    if (!isJsonEmpty(data)) {
                        if (("seed" in data) && ("id" in data)) {
                            const mainMessage = "Enter this code to another devices or browsers you want to sync:";
                            localStorage.setItem("winterly-seed", data.seed);
                            localStorage.setItem("winterly-id", data.id);
                            localStorage.setItem("winterly-uid", data.uid);
                            const syncCode = getSyncCode();
                            this.setState({
                                syncCode: syncCode,
                                syncID: data.id,
                                getSeed: true,
                                mainMessage: mainMessage
                            })
                        }
                    }
                });
        })
    }

    handleUnsyncButton() {
        this.setState({
            getSeed: false,
            cloneParent: true
        }, () => {
            localStorage.removeItem("winterly-seed");
            localStorage.removeItem("winterly-id");
        })
    }

    componentDidMount() {
        const isSynced = !((localStorage.getItem("winterly-id") == null) && (localStorage.getItem("winterly-seed") == null));

        if (isSynced) {
            const syncCode = getSyncCode();
            this.setState({
                syncCode: syncCode,
                syncID: localStorage.getItem("winterly-id")
            })
        } else {
            this.loadData();
        }
    }

    render() {

        if (!this.state.cloneParent) {
            return (
                <div>
                    {
                        (!this.state.getSeed && !this.state.cloneParent) ?
                            <div>
                                <p>{this.state.mainMessage}</p>
                                <svg style={{marginLeft: "30%"}} width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                    <g>
                                        <animateTransform attributeName="transform" type="rotate" values="0 33 33;270 33 33" begin="0s" dur="1.4s" fill="freeze" repeatCount="indefinite" />
                                        <circle fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" strokeDasharray="187" strokeDashoffset="610">
                                            <animate attributeName="stroke" values="#4285F4;#DE3E35;#F7C223;#1B9A59;#4285F4" begin="0s" dur="5.6s" fill="freeze" repeatCount="indefinite" />
                                            <animateTransform attributeName="transform" type="rotate" values="0 33 33;135 33 33;450 33 33" begin="0s" dur="1.4s" fill="freeze" repeatCount="indefinite" />
                                            <animate attributeName="stroke-dashoffset" values="187;46.75;187" begin="0s" dur="1.4s" fill="freeze" repeatCount="indefinite" />
                                        </circle>
                                    </g>
                                </svg>
                            </div>
                            :
                            <div>
                                <p>{this.state.mainMessage}</p>
                                <p className="syncCodeLabel">{this.state.syncCode}</p>
                                {/* <button className="deleteStoryButton settings" style={{ float: "left", marginTop: "0px" }} onClick={this.handleUnsyncButton}>Un-sync This Browser</button> */}
                            </div>
                    }
                </div>
            );
        }

        else if (this.state.cloneParent) {
            return (
                <div>
                    <button className="saveStoryButton" onClick={this.loadData}>Enter a code</button>
                    <button className="saveStoryButton" onClick={this.loadData}>Sync stories</button>
                </div>
            )
        }
    }
}

export default SyncProcess;